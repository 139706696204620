<template>
    <!--An email detail page-->
    <div>
        <div class='email-subject'>{{subject}}</div>
        <div class='email-from'>{{from}}</div>
        <div class='email-to'>{{to}}</div>
        <div class='email-body' v-html='body'></div>
    </div>

</template>

<script>
    import { emails } from '@/util/apiRequests';

    export default {
        name: 'EmailLogDetail',
        props:{

        },
        data() {
            return {
                subject: '',
                from: '',
                to: '',
                body: ''
            };
        },
        async created() {
            await this.getEmail();
        },
        methods: {
            async getEmail() {
                try {
                    const res = await this.$api.get(emails.getEmail(this.$route.params.record_id));
                    let { Subject, mail_from, mail_to, mail_html } = res.data || {};
                    this.subject = Subject;
                    this.from = mail_from;
                    this.to = mail_to;
                    this.body = mail_html;

                } catch (err) {
                    this.$toasted.error('Failed to fetch email.');
                }
            }
        },
        computed: {

        },
        watch: {

        },


    };
</script>

<style scoped>

</style>